import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getBreakpoint } from "../../utils/theme"

export const UserZonePageWrapper = styled.main`
  min-height: calc(
    100vh - var(--nav-height) - var(--nav-social-height) - var(--footer-height)
  );
  background-color: #f2f2f2;
  box-sizing: border-box;
`

export const UserZonePageContent = styled.div`
  margin-top: calc(var(--nav-height) + var(--nav-social-height));
  section {
    padding: 0.5rem;
  }

  ${getBreakpoint("tablet")} {
    section {
      padding: 2rem 0.5rem;
    }
  }
`

export const PostsVertialCarousel = styled.div`
  padding: 3rem 0 5rem 0;
  display: flex;
  width: 100%;
  max-width: calc(var(--content-wide-max-width) + 2rem);
  margin: auto;
  align-items: top;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;

  ${getBreakpoint("mobile l", "MAX")} {
    padding: 1rem 1rem 3rem 1rem;
  }
`
