import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import * as Styled from "./styled"

const BlogBlock = ({ title, postsList }) => {
  return (
    <Styled.BlogBlockWrapper>
      <Styled.BlogBlockContent>
        <Styled.BlogBlockTitleWrapper>{title}</Styled.BlogBlockTitleWrapper>

        <Styled.BlogBlockPostsList>
          {postsList.map((post) => (
            <SinglePost {...post} />
          ))}
        </Styled.BlogBlockPostsList>
      </Styled.BlogBlockContent>
    </Styled.BlogBlockWrapper>
  )
}

const SinglePost = ({ abstract, cover, /* locale, */ meta, title, slug }) => {
  const { createdAt } = meta

  return (
    <Styled.BlogBlockSinglePostWrapper>
      <Styled.BlogBlockSinglePostCoverWrapper>
        <GatsbyImage
          image={cover.gatsbyImageData}
          alt={cover.alt}
          style={{ width: "100%" }}
        />
      </Styled.BlogBlockSinglePostCoverWrapper>
      <Styled.BlogBlockSinglePostInfo>
        <Styled.BlogBlockSinglePostDate>
          {createdAt}
        </Styled.BlogBlockSinglePostDate>
        <Styled.BlogBlockSinglePostTitle>
          <Link to={`/${slug}`}>{title}</Link>
        </Styled.BlogBlockSinglePostTitle>
        <Styled.BlogBlockSinglePostAbstract>
          {abstract}
        </Styled.BlogBlockSinglePostAbstract>
      </Styled.BlogBlockSinglePostInfo>
    </Styled.BlogBlockSinglePostWrapper>
  )
}

export default BlogBlock
