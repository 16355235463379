import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { getBreakpoint, getColor } from "../../../utils/theme"

export const CoverFrontBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
`

export const CoverFrontBlockImage = styled(GatsbyImage)`
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  height: 28rem;
  ${getBreakpoint("laptop", "MAX")} {
    height: 18rem;
  }
`

export const CoverFrontBlockCenter = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 2;

  z-index: 1;

  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;

  h1 {
    font-weight: 700;
    font-size: 2rem;
    margin: 1rem 0;
    color: var(--color-primary-dark);
  }

  ${getBreakpoint("laptop", "MAX")} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    h1 {
      font-size: 1.5rem;
    }
  }
`

export const CoverFrontBlockCenterImage = styled(GatsbyImage)`
  height: 2rem;
  width: 2rem;
`
