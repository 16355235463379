import styled from "styled-components"

export const BnbdaysButtonWrapper = styled.button`
  border-radius: 3.125rem;
  background-color: var(--color-primary-dark);
  border: none;

  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: white;

  padding: 0.5rem 1rem;

  cursor: pointer;

  &.outline {
    border: 0.125rem solid var(--color-gray-dark);
    background-color: transparent;
    color: var(--color-gray-dark);
  }
`
