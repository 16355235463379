import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import * as Styled from "./styled"

const CoverFrontBlock = (props) => {
  const { title, textColor, titleIcon, cover } = props

  return (
    <Styled.CoverFrontBlock>
      <Styled.CoverFrontBlockImage
        alt={cover.alt}
        image={cover.gatsbyImageData}
      ></Styled.CoverFrontBlockImage>
      <Styled.CoverFrontBlockCenter>
        <Styled.CoverFrontBlockCenterImage
          imgStyle={{ objectFit: "contain" }}
          image={titleIcon.gatsbyImageData}
          alt=""
        ></Styled.CoverFrontBlockCenterImage>
        <h1>{title}</h1>
      </Styled.CoverFrontBlockCenter>
    </Styled.CoverFrontBlock>
  )
}

export default CoverFrontBlock
