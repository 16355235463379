import React, { createElement, Fragment } from "react"
import loadable from "@loadable/component"

// blocks
import { HeroBlock } from "../../components/blocks/index"
import CoverFrontBlock from "../../components/blocks/coverFrontBlock"

const BlogBlock = loadable(() => import("../../components/blocks/index"), {
  resolveComponent: (components) => components.BlogBlock,
})

// block-component mapping
const MODELS = {
  blog_block: BlogBlock,
  hero_block: HeroBlock,
  cover_front_block: CoverFrontBlock,
}

interface SliceMachineProps {
  data: any
  modelAccessor: (arg0: any) => string
  index: Number
  forwardRef?: React.RefObject<HTMLElement>
  children?: any | any[]
}

const SliceMachine = (props: SliceMachineProps) => {
  const { data, modelAccessor, index, forwardRef, children } = props

  let blockTypeKey = modelAccessor(data)
  const blockComponent = MODELS[blockTypeKey]

  if (!blockTypeKey) {
    console.groupCollapsed(
      `%cUnknown model for block number ${index}`,
      "font-weight: 700; font-size: .9rem; color: red;"
    )
    console.error(JSON.stringify(props, null, 2))
    console.groupEnd()

    return <Fragment />
  }

  if (!blockComponent) {
    console.groupCollapsed(
      `Component with key %c${blockTypeKey} %cnot found!`,
      "font-weight: 700; font-size: .9rem; color: blue;",
      "font-weight: 500;"
    )
    console.log("Its data looks like this:")
    console.log(JSON.stringify(data, null, 2))
    console.groupEnd()
    return <Fragment />
  }

  return createElement(blockComponent, { ...data, children, forwardRef })
}

export default SliceMachine
