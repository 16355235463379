import React, { createElement, useEffect } from "react"

import * as Styled from "./styled"

const BnbdaysButton = (props) => {
  const { children, className } = props
  return (
    <Styled.BnbdaysButtonWrapper
      className={`bnbdays-button ${className}`}
      {...props}
    >
      {children}
    </Styled.BnbdaysButtonWrapper>
  )
}

export default BnbdaysButton
