import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ReactMarkdown from "react-markdown"
import * as Styled from "./styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticSetting } from "../../utils/staticSetting"
import SliceMachine from "../../utils/sliceMachine"
import { useEffect } from "react"
import { useAppStateDispatch } from "../../providers/context-provider/app-context"
import PostCard from "../../components/blocks/post-card"

// markup
const UserZonePage = ({
  data: { pageData, staticSetting, postsData },
  pageContext: { userZonePageId, userType, isRoot },
}) => {
  const { seoMetaTags, locale, blocks, slug } = pageData?.edges?.[0]?.node || {}

  const staticSettings = StaticSetting(staticSetting?.nodes)

  const appStateDispatch: any = useAppStateDispatch()

  useEffect(() => {
    appStateDispatch({
      type: "activeZoneUserTab",
      value: {
        activeZoneUserTab: `/${slug}`,
      },
    })
  }, [])

  return (
    <Styled.UserZonePageWrapper>
      <HelmetDatoCms
        seo={seoMetaTags}
        htmlAttributes={{
          lang: locale,
        }}
      >
        <link
          rel="canonical"
          href={`https://blog.bnbdays.com/${isRoot ? "" : slug}`}
        ></link>
      </HelmetDatoCms>
      <Styled.UserZonePageContent>
        {blocks?.map((b, i) => (
          <SliceMachine
            data={{ ...b, staticSettings }}
            modelAccessor={(d) => d.model.apiKey}
            key={i}
            index={i}
          />
        ))}
        <Styled.PostsVertialCarousel>
          {!(postsData?.nodes?.length > 0) && (
            <h1
              style={{
                textAlign: "center",
                width: "100%",
                color: "var(--color-primary-dark)",
              }}
            >
              Aún no hay artículos, vuelve dentro de poco...
            </h1>
          )}
          {postsData.nodes.map((post, index) => (
            <PostCard
              data={{
                ...post,
                ctaText: staticSettings.get("post_card_cta")?.display,
              }}
              key={`user-zone-post-${index}`}
            ></PostCard>
          ))}
        </Styled.PostsVertialCarousel>
      </Styled.UserZonePageContent>
    </Styled.UserZonePageWrapper>
  )
}

export default UserZonePage

export const query = graphql`
  query UserZonePageQuery(
    $userZonePageId: String!
    $locale: String!
    $userType: String!
  ) {
    navBarData: allDatoCmsNavigationBar(filter: { locale: { eq: $locale } }) {
      nodes {
        contactLink
        contactText
        locale
        pressLink
        pressText
        zonaBiambideraText
        zonaPlayeraText
        showZonaBiambidera
        showZonaPlayera
        showPress
        showContact
        showSearch
        languageDisplayName
        linkedinUrl
        facebookUrl
        instagramUrl
      }
    }
    footer: allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        links {
          name
          uri
        }
      }
    }
    pageData: allDatoCmsPage(filter: { id: { eq: $userZonePageId } }) {
      edges {
        node {
          seoMetaTags {
            tags
          }
          locale
          slug
          title
          blocks {
            ... on DatoCmsCoverFrontBlock {
              id
              title
              cover {
                alt
                gatsbyImageData
              }
              titleIcon {
                gatsbyImageData
              }
              model {
                apiKey
              }
            }
          }
        }
      }
    }
    staticSetting: allDatoCmsStaticSetting(
      filter: { locale: { eq: $locale }, tag: { eq: "post" } }
    ) {
      nodes {
        name
        display
        media {
          gatsbyImageData
        }
      }
    }
    postsData: allDatoCmsPost(
      limit: 10
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: {
        locale: { eq: $locale }
        label: { canonical: { eq: $userType } }
      }
    ) {
      nodes {
        title
        slug
        abstract
        label {
          name
          backgroundColor
        }
        cover {
          alt
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            forceBlurhash: false
          )
        }
      }
    }
  }
`
