import styled from "styled-components"
import { getBreakpoint, getColor } from "../../../utils/theme"

export const BlogBlockSinglePostAbstract = styled.p`
  line-height: 1.5;
`

export const BlogBlockSinglePostTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 250;

  margin: 1rem 0;

  ${getBreakpoint("tablet")} {
    font-size: 2rem;
  }
`

export const BlogBlockSinglePostDate = styled.div`
  margin-top: 1rem;
`

export const BlogBlockSinglePostInfo = styled.div``

export const BlogBlockSinglePostCoverWrapper = styled.div`
  width: 100%;
`

export const BlogBlockSinglePostWrapper = styled.div`
  margin-bottom: 1.85rem;
  padding-bottom: 1.5rem;

  border-bottom: 1px solid ${getColor("text:black")};

  ${getBreakpoint("tablet")} {
    margin-bottom: 0;

    :first-child {
      grid-column: 1/-1;

      display: flex;
      align-items: center;

      ${BlogBlockSinglePostCoverWrapper} {
        flex: 0 1 100%;
        margin-right: 2rem;
      }

      ${BlogBlockSinglePostInfo} {
        flex: 1 1 100%;

        ${BlogBlockSinglePostTitle} {
          font-size: 2.85rem;
        }

        ${BlogBlockSinglePostAbstract} {
          font-size: 1.3125rem;
          font-weight: 300;

          line-height: 1.75;
        }
      }
    }
  }
`

//

export const BlogBlockPostsList = styled.div`
  ${getBreakpoint("tablet")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    row-gap: 2.5rem;
  }

  ${getBreakpoint("laptop")} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const BlogBlockTitleWrapper = styled.div``

export const BlogBlockContent = styled.div``

export const BlogBlockWrapper = styled.section``
