import { navigate } from "gatsby"
import React from "react"
import { StructuredText } from "react-datocms"
import SliceMachine from "../../../utils/sliceMachine"
import BnbdaysButton from "../../atoms/bnbdays-button"
import Label from "../../atoms/label"
import ShowcaseMetric from "../../atoms/showcaseMetric"

import * as Styled from "./styled"

const HeroBlock = (props) => {
  const {
    title,
    cover,
    content,
    label: { name: labelName, backgroundColor },
    ctaLink,
    staticSettings,
  } = props

  return (
    <Styled.HeroBlockWrapper>
      <Styled.HeroBlockBackgroundImage
        alt={cover.alt}
        image={cover.gatsbyImageData}
      ></Styled.HeroBlockBackgroundImage>
      <Styled.HeroBlockCard>
        <Label backgroundColor={backgroundColor}>{labelName}</Label>
        <Styled.CardTitle>{title}</Styled.CardTitle>
        <Styled.CardAbstract children={content}></Styled.CardAbstract>
        <BnbdaysButton className="outline" onClick={() => navigate(ctaLink)}>
          {staticSettings.get("post_card_cta")?.display}
        </BnbdaysButton>
      </Styled.HeroBlockCard>
    </Styled.HeroBlockWrapper>
  )
}

export default HeroBlock
