import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { getBreakpoint, getColor } from "../../../utils/theme"

export const HeroBlockWrapper = styled.div`
  width: 100%;
  max-width: 67.5rem;
  display: grid;
  grid-template-columns: 17.5rem 4.375rem minmax(20rem, 45.625rem);
  grid-template-rows: 1fr 10fr 1fr;

  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  box-sizing: border-box;

  ${getBreakpoint("laptop", "MAX")} {
    grid-template-columns: 10.5rem minmax(4.375rem, 14rem) minmax(
        8rem,
        45.625rem
      );
  }

  ${getBreakpoint("mobile l", "MAX")} {
    grid-template-columns: 1fr;
    grid-template-rows: 10rem 4rem minmax(1rem, auto);
    with: 100%;
    padding: 0;
  }
`
export const HeroBlockCard = styled.div`
  grid-column: 1 / 3;
  grid-row: 2 / 2;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  z-index: 1;
  box-sizing: border-box;
  padding: 1.5rem 2.625rem 1rem 1rem;
  height: fit-content;
  align-self: center;

  ${getBreakpoint("mobile l", "MAX")} {
    grid-column: 1 / 1;
    grid-row: 2 / 4;
    width: calc(100% - 2rem);
    margin: auto;
  }
`

export const HeroBlockBackgroundImage = styled(GatsbyImage)`
  grid-column: 2 / 4;
  grid-row: 1 / 4;
  border-radius: 0.625rem;
  width: 100%;
  min-height: 32.5rem;

  ${getBreakpoint("mobile l", "MAX")} {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
    padding: 0;
    width: 100%;
    border-radius: 0;
    min-height: 16.25rem;
  }
`

export const CardTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--color-primary-dark);
  margin: 0.5rem 0;
`

export const CardAbstract = styled(ReactMarkdown)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  margin: 0 0 0.5rem 0;
`
